<script>
  import { RecoveryResult, STATUS_DELIVERED } from "~/libs/constants";

  export let record;
  let color;
  let statusIcon;
  (() => {
    if (
      record.lost ||
      record.misdeliveryState == RecoveryResult.FAILURE ||
      record.returnStatus != null ||
      record.notActuallyReceived
    ) {
      // 紛失 or 誤送後回収不可 or 返品対象 or 引渡予定の荷物リストに含まれていたが引渡時に存在しなかった の場合、配達不能アイコンを表示
      color = "red";
      statusIcon = "do_not_disturb_on";
    } else if (record.status !== STATUS_DELIVERED) {
      // 配達済以外の場合、チェックアイコン(グレー)を表示
      color = "lightgray";
      statusIcon = "check_circle_outline";
    } else {
      // 配達済の場合、チェックアイコン(ネイビー)を表示
      color = "#1976d2";
      statusIcon = "check_circle";
    }
  })();
</script>

<span class="material-icons" style="color: {color};">{statusIcon}</span>

<style>
  span {
    padding: 5px 0 0 10px;
  }
</style>
